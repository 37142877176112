import { Component, OnInit, ElementRef, Renderer2, Input, ViewChild, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DataService } from '../../providers/data/data.service';
import { SearchProductsQuery, SearchProductsQueryVariables } from '../../../common/generated-types';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SEARCH_PRODUCTS } from '../../../common/graphql/documents.graphql';
import { SwiperContainer } from 'swiper/element';
import { CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

@Component({
  selector: 'vsf-product-swiper',
  templateUrl: './product-swiper.component.html',
  styleUrls: ['./product-swiper.component.scss']
})
export class ProductSwiperComponent implements OnInit, AfterViewInit, OnDestroy {
  products$: Observable<SearchProductsQuery['search']['items']>;
  displayedProducts: SearchProductsQuery['search']['items'] = [];
  selectedIndex = 1;
  totalSlides = 0;
  gridRows = 1;
  private sub: Subscription;

  @Input() collectionSlug: string;
  @Input() headerTitle: string;
  @Input() channelId: string;
  @Input() layoutMode: string;
  @Input() swipeDirectoin: string = 'horizontal';
  @Input() itemsPerRow: number;
  @Input() itemsPerSlide: number;
  @Input() displayBuyButton: boolean = false;
  @Input() displayPrice: boolean = false;
  @Input() displaySubtitle: boolean = false;
  @Input() displayPagination: boolean = false;
  @Input() imgSize: 'sm-h' | 'sm-v' | 'md' | 'lg' = 'md';

  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;

  constructor(
    private dataService: DataService,
    private chageDetector: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.gridRows = this.itemsPerSlide / this.itemsPerRow;
    this.products$ = this.dataService.query<SearchProductsQuery, SearchProductsQueryVariables>(SEARCH_PRODUCTS, {
      input: {
        take: 100,
        groupByProduct: true,
        channelId: this.channelId,
        collectionSlug: this.collectionSlug || '',
      },
    }).pipe(
      map(data => {
        return data.search.items;
      }),
    );

    this.sub = this.products$.subscribe(products => {
      this.displayedProducts = products;
      this.totalSlides = Math.ceil(this.displayedProducts.length / this.itemsPerSlide);
    });
  }

  ngAfterViewInit() {
    if(this.swiper.nativeElement.swiper) {
      this.swiper.nativeElement.swiper.onAny((eventName: string) => this.slideEvent(eventName));
    }
  }

  ngOnDestroy() {
    if (this.sub) {
        this.sub.unsubscribe();
    }
  }

  slideEvent(eventName: string) {
    if(eventName === 'slideChange') {
      this.selectedIndex = Math.ceil(this.swiper.nativeElement.swiper.activeIndex / this.itemsPerRow) + 1;
    }
  }

  swipeToNext() {
    if(!this.swiper.nativeElement.swiper.isEnd) {
      this.swiper.nativeElement.swiper.slideNext();
    }
  }

  swipeToPrev() {
    if(!this.swiper.nativeElement.swiper.isBeginning) {
      this.swiper.nativeElement.swiper.slidePrev();
    }
  }

  trackByProductId(index: number, item: SearchProductsQuery['search']['items'][number]): string {
    return item.productId;
  }
}