import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../providers/data/data.service';
import { GET_REVIEWS_BY_IDS } from '../../../common/graphql/documents.graphql';
import { GetReviewsByIdsQuery, GetReviewsByIdsQueryVariables } from '../../../common/generated-types';
import { map, take } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';

interface Review {
    id: string;
    title: string;
    rating: number;
    content: string;
    author: string;
    date: string;
    datetime: string;
}

@Component({
    selector: 'vsf-top-reviews',
    templateUrl: './top-reviews.component.html',
    styleUrls: ['./top-reviews.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopReviewsComponent implements OnInit {
    @Input() reviewIds: string[] = [];
    @Input() isMobile: boolean;

    reviews: Review[] = [];
    featuredReview: Review | null = null;
    reviewsList: Review[] = [];

    constructor(private dataService: DataService,
                private ChangeDetector: ChangeDetectorRef,
                private logger: NGXLogger
    ) {}
    ngOnInit() {
        if (this.reviewIds?.length > 0) {
            const reviewsObservable = this.dataService.query<GetReviewsByIdsQuery, GetReviewsByIdsQueryVariables>(
                GET_REVIEWS_BY_IDS,
                { reviewIds: this.reviewIds.filter(notNullOrUndefined) }
            ).pipe(
                take(1),
                map((result: any) => result?.getReviewsByIds)
            );
            reviewsObservable.subscribe(
                (reviews) => {
                    // Filter out null reviews
                    const validReviews = reviews.filter((review: Review) => review !== null); 
                    this.reviews = this.reviewIds.map((id: string) => validReviews.find((review: Review) => review.id === id)).filter(notNullOrUndefined);
                    // Optionally, handle the case where some reviews were not found
                    if (validReviews.length < this.reviewIds.length) {
                        // You could log a warning, display a message to the user, etc.
                        this.logger.warn('Some reviews were not found.');
                    }
                    this.reviewsList = this.reviews;
                    this.logger.debug(`[TopReviews]reviews:${this.reviews.length} ${JSON.stringify(this.reviews)}`);
                    this.ChangeDetector.detectChanges();
                },
                (error) => {
                    this.logger.error('Error fetching reviews:', error);
                }
            );
        }

    }

}
