<ng-template [ngIf]="product">
    <div *ngIf="layoutMode==='vertical'" class="flex flex-col">
        <a class="block" prefetch='intent' [routerLink]="[productBaseUrl, product.slug]">
            <div class="w-full h-full full-size">
                <img loading="lazy"
                    [src]="product.productAsset | assetPreview:'small'"
                    [alt]="product.name"
                    style="pointer-events: none;"
                    class="w-full h-full object-cover rounded-xl aspect-[1/1]"
                />
            </div>
            <div class="product-name font-bold mt-2 mb-1 text-[#2E3742]" [title]="product.productName">
                {{ product.productName }}
            </div>
            <div *ngIf="displaySubtitle" class="product-subtitle mb-1 text-[#95A1AF]" [title]="product.productSubtitle" >
                {{ product.productSubtitle || '&nbsp;' }}
            </div>
            <div *ngIf="displayPrice" class="flex flex-col product-price font-medium font-semibold text-[#2E3742]">
                <ng-container *ngIf="msrp && discountPercentage; else onlyPrice">
                    <div class="text-base">
                        <span class="text-grey-500 line-through">
                            {{ msrp | formatPrice }}
                        </span>
                        <span class="text-red-500 ml-2">
                            {{ product.price.min | formatPrice }} ({{ discountPercentage | number:'1.0-0' }}% off)
                        </span>
                    </div>
                </ng-container>
                <ng-template #onlyPrice>
                    <div>
                        <ng-template [ngIf]="product.price.min === product.price.max" [ngIfElse]="range">
                            {{ product.price.min | formatPrice }}
                        </ng-template>
                            <ng-template #range>
                                From {{ product.price.min | formatPrice }}
                            </ng-template>
                        </div>
                </ng-template>
            </div>
        </a>
        <div *ngIf="displayBuyButton" class="mt-2 w-1/2">
            <button (click)="addToCartAndOpenDrawer()"
                class="bg-[#29319C] text-white px-8 py-2 whitespace-nowrap rounded-lg border border-gray-300 hover:bg-zinc-600 focus:outline-none pointer-events-auto"
                [ngClass]="{
                    'bg-zinc-600': (inFlight|| !inStock),
                    'bg-[#29319C] hover:bg-zinc-600': !inFlight
                }"
                [disabled]="inFlight || !inStock"
            >
                {{inStock?'Shop Now':'Out of Stock'}}
            </button>
        </div>
    </div>
    <div *ngIf="layoutMode==='horizontal'" class="flex flex-row w-full h-full">
        <div class="w-full">
            <div class="w-full h-full full-size">
                <a prefetch='intent' [routerLink]="[productBaseUrl, product.slug]">
                    <img loading="lazy"
                        [src]="product.productAsset | assetPreview:'small'"
                        [alt]="product.name"
                        style="pointer-events: none;"
                        class="w-full h-full object-cover rounded-xl aspect-[1/1]"
                    />
                </a>
            </div>
        </div>
        <div class="w-2/3 pl-2 flex flex-col">
            <div class="flex-grow">
                <div class="h-2"></div>
                <div class="font-bold text-[#2E3742] product-name">
                    {{ product.productName }}
                </div>
                <div *ngIf="product.productSubtitle" class="product-subtitle text-[#95A1AF]">
                    {{ product.productSubtitle || '&nbsp;' }}
                </div>
                <div *ngIf="displayPrice" class="flex text-base font-medium font-semibold text-[#2E3742]">
                    <ng-container *ngIf="msrp && discountPercentage; else onlyPrice">
                        <div class="text-base">
                            <span class="text-grey-500 line-through">{{ msrp | formatPrice }}</span>
                            <span class="text-red-500 ml-2">{{ product.price.min | formatPrice }} ({{ discountPercentage | number:'1.0-0' }}% off)</span>
                        </div>
                    </ng-container>
                    <ng-template #onlyPrice>
                        <div>
                            <ng-template [ngIf]="product.price.min === product.price.max" [ngIfElse]="range">
                            {{ product.price.min | formatPrice }}
                            </ng-template>
                            <ng-template #range>
                            From {{ product.price.min | formatPrice }}
                            </ng-template>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div *ngIf="displayBuyButton" class="w-full mt-2">
                <button (click)="addToCartAndOpenDrawer()"
                    class="bg-[#29319C] text-white px-8 py-2 whitespace-nowrap rounded-lg border border-gray-300 hover:bg-zinc-600 focus:outline-none pointer-events-auto"
                    [ngClass]="{
                        'bg-zinc-600': (inFlight|| !inStock),
                        'bg-[#29319C] hover:bg-zinc-600': !inFlight
                    }"
                    [disabled]="inFlight || !inStock"
                    [style.width.px]="200">
                    {{inStock?'Shop Now':'Out of Stock'}}
                </button>
            </div>
        </div>
    </div>
</ng-template>
