<div class="product-carousel flex flex-col">
  <div *ngIf="headerTitle" [style.z-index]="4" class="flex mt-2 mb-2 mr-2 justify-between text-gray-500 w-100">
    <p class="fa-vertical-bar-medium text-center text-2xl text-[#2E3742]">{{headerTitle}}</p>
    <div>
      <div *ngIf="displayPagination">
        <button class="fa-chevron-left-medium mr-2" (click)="swipeToPrev()">< </button>
        <span>{{ selectedIndex }}</span> / <span>{{ totalSlides }}</span>
        <button class="fa-chevron-right-medium ml-2" (click)="swipeToNext()">> </button>
      </div>
    </div>
  </div>
  <div class="w-100 ml-1 mr-1">
    <swiper-container appSwiper #swiper
      [slidesPerView]="itemsPerRow"
      [slidesPerGroup]="itemsPerRow"
      [direction]="swipeDirectoin"
      [grid]="{'rows': gridRows, 'fill': 'row'}"
      space-between=10
      rewind=true
      class="flex object-cover custom-pagination"
    >
        <swiper-slide *ngFor="let product of displayedProducts; let i = index"
            class="product-grid"
            [ngClass]="{'selected-slide': selectedIndex === i}">
            <div class="flex w-full h-full">
                <vsf-product-card
                  [product]="product"
                  [displayBuyButton]="displayBuyButton"
                  [displayPrice]="displayPrice"
                  [displaySubtitle]="displaySubtitle"
                  [layoutMode]="layoutMode"
                  [imgSize]="imgSize"
                  class="card"
                  [style.height.px]="layoutMode === 'horizontal' ? 180 : undefined">
                </vsf-product-card>
            </div>
        </swiper-slide>
    </swiper-container>
  </div>
</div>