import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Asset } from '../../../common/generated-types';
import { EmailSubscriptionFormData } from '../../../common/interfaces';

@Component({
    selector: 'vsf-email-subscription-form',
    templateUrl: './email-subscription-form.component.html',
    styleUrls: ['./email-subscription-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailSubscriptionFormComponent implements OnInit {
    @Input() emailSubscriptionSection: EmailSubscriptionFormData;

    title: string;
    subtitle: string;
    backgroundImage: Asset | null;
    shadowOpacity = 0;
    constructor(private logger: NGXLogger) {}

    email: string = '';

    ngOnInit() {
        this.logger.info('Email Subscription Section', JSON.stringify(this.emailSubscriptionSection));
        this.title = this.emailSubscriptionSection?.title;
        this.subtitle = this.emailSubscriptionSection?.subtitle;
        this.backgroundImage = this.emailSubscriptionSection?.backgroundImage;
        this.shadowOpacity = (1.0-this.emailSubscriptionSection?.shadowTransparency) || this.shadowOpacity;
    }

    onSubmit() {
        if (this.email) {
          this.logger.debug('Email submitted:', this.email);
        }
    }
}
