import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { GetProductAllDetailQuery, SearchProductsQuery } from '../../../common/generated-types';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { CartService } from '../../../core/providers/cart/cart.service';
import { AddToCartResult } from '../../../common/enums';
import { extractStoreUrl } from '../../../common/utils/extract-store-url';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'vsf-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent implements OnInit {
    @Input() product: SearchProductsQuery['search']['items'][number];
    @Input() displayBuyButton: boolean = false;
    @Input() displaySubtitle: boolean = false;
    @Input() displayPrice: boolean = false;
    @Input() imgSize: 'sm-h' | 'sm-v' | 'md' | 'lg' = 'md';
    @Input() layoutMode: string = 'vertical';
    msrp: number | null = null;
    discountPercentage: number | null = null;
    inFlight = false;
    inStock = true;
    productDetails: GetProductAllDetailQuery['product'];
    productBaseUrl = '';
    private subscription = new Subscription();

    constructor(
        private changeDetector: ChangeDetectorRef,
        private cartService: CartService,
        private route: ActivatedRoute,
        private logger: NGXLogger
    ) {}

    ngOnInit() {
        this.productBaseUrl = `${extractStoreUrl(this.route.snapshot)}/dp`;
        if (this.product) {
            this.inStock = this.product.inStock;
            this.updatePricingInfo();
            this.changeDetector.markForCheck();
        }
    }

    updatePricingInfo() {
        if(this.product.price?.__typename === 'PriceRange' && this.product.price.min !== null && this.product.price.max !== null) {
            if(this.product.price.min === this.product.price.max && this.product.productVariantMSRP) {
                const price = this.product.price.min;
                this.msrp = this.product.productVariantMSRP;
                if (this.msrp && this.msrp > price) {
                    this.discountPercentage = ((this.msrp - price) / this.msrp) * 100;
                }
            }
        }
    }

    addToCartAndOpenDrawer() {
        if (!this.product) {
          return;
        }
        this.inFlight = true;
        this.changeDetector.markForCheck();

        this.subscription.add(
          this.cartService.addToCartAndOpenDrawer(this.product.productVariantId).subscribe({
            next: (result: AddToCartResult) => {
              this.inFlight = false;
              if (result === AddToCartResult.OUT_OF_STOCK) {
                  this.inStock = false;
              }
              this.changeDetector.markForCheck();
          },
            error: err => {
              this.inFlight = false;
              this.logger.error('Error in addToCartAndOpenDrawer', err);
              this.changeDetector.markForCheck();
            },
          }),
        );
    }
}
