import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { CartService } from '../../../core/providers/cart/cart.service';
import { BannerButtonData, HeroSectionData } from '../../../common/interfaces';
import { NotificationService } from '../../../core/providers/notification/notification.service';
import { Asset } from '../../../common/generated-types';
import { extractStoreUrl } from '../../../common/utils/extract-store-url';

@Component({
    selector: 'vsf-hero-section',
    templateUrl: './hero-section.component.html',
    styleUrls: ['./hero-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroSectionComponent implements OnInit {
    @Input() heroSection: HeroSectionData;

    isMobile: boolean | null = null;

    layoutType: 'left-text-full-media' | 'right-text-full-media' = 'left-text-full-media';
    title: string;
    subtitle: string;
    titleSize: string;
    titleSizeMobile: string;
    textColor: string;
    medias: Asset[];
    button: BannerButtonData | null;
    shadowOpacity = 0;
    defaultHeroFontSize = 40;
    mobileHeroFontSizeRatio = 0.4;
    inFlight: boolean = false;
    baseUrl = '';
    notificationService: NotificationService;
    private subscription = new Subscription();
    private static readonly BREAK_POINT_CONFIG = [
      { query: '(max-width: 300px)', ratio: 0.3 },
      { query: '(max-width: 400px)', ratio: 0.37 },
      { query: '(max-width: 500px)', ratio: 0.45 },
      { query: '(max-width: 600px)', ratio: 0.57 },
      { query: '(max-width: 700px)', ratio: 0.65 },
      { query: '(min-width: 701px)', ratio: 0.75 }
    ];

    constructor(@Inject(PLATFORM_ID) private platformId: object,
                private changeDetector: ChangeDetectorRef,
                private router: Router,
                private route: ActivatedRoute,
                private cartService: CartService,
                private breakpointObserver: BreakpointObserver,
                private logger: NGXLogger) {}

    ngOnInit() {
        this.checkViewport();
        this.baseUrl = extractStoreUrl(this.route.snapshot);
        this.logger.info('Hero Section', JSON.stringify(this.heroSection));
        this.layoutType = this.heroSection?.layoutType;
        this.title = this.heroSection?.title;
        this.titleSize = `${this.heroSection?.titleSize}px`;
        if (isPlatformBrowser(this.platformId)) {
          const breakpointQueries = HeroSectionComponent.BREAK_POINT_CONFIG.map(config => config.query);
          this.breakpointObserver
            .observe(breakpointQueries)
            .subscribe(result => {
              const breakpoints = result.breakpoints;
              const matchedBreakpoint = HeroSectionComponent.BREAK_POINT_CONFIG.find(config => breakpoints[config.query]);
              if (matchedBreakpoint) {
                this.mobileHeroFontSizeRatio = matchedBreakpoint.ratio;
              } else {
                this.mobileHeroFontSizeRatio = 0.75;
              }
              this.titleSizeMobile = `${(this.heroSection?.titleSize || this.defaultHeroFontSize) * this.mobileHeroFontSizeRatio}px`;
              this.changeDetector.markForCheck();
            });
        }
        this.subtitle = this.heroSection?.subtitle;
        this.textColor = this.heroSection?.textColor;
        this.medias = this.heroSection?.medias;
        this.shadowOpacity = (1.0-this.heroSection?.shadowTransparency) || this.shadowOpacity;
        this.button = safeJSONParse<BannerButtonData>(this.heroSection?.button, this.logger);
        this.changeDetector.markForCheck();
    }

    @HostListener('window:resize', [])
    onResize() {
      this.checkViewport();
    }

    private checkViewport() {
      if(isPlatformBrowser(this.platformId)){
        const mobileIndicator = document.getElementById('mobile-indicator');
        if(mobileIndicator && window) {
            this.isMobile = window.getComputedStyle(mobileIndicator).display !== 'none';
        }
      }
    }

    buttonClick(event: Event) {
        this.logger.debug('Button clicked', this.button);
        if(this.button && this.button.actionType) {
            if(this.button.actionType === 'navigate-to') {
                if(this.button.navigateType === 'navigate-to-product') {
                    this.router.navigate([`${this.baseUrl}/dp`, this.button.targetProduct?.slug]);
                } else if(this.button.navigateType === 'navigate-to-collection') {
                    this.router.navigate([`${this.baseUrl}/gp`, this.button.targetCollection?.slug]);
                } else if(this.button.navigateType === 'navigate-to-page') {
                    this.router.navigate([this.button.targetPage]);
                }
            } else if(this.button.actionType === 'add-variant-to-cart') {
                if(this.button.selectedVariant) {
                    this.addToCartAndOpenDrawer(this.button.selectedVariant);
                }
            }
        }
    }

    addToCartAndOpenDrawer(variantId: string) {
        this.inFlight = true;
        this.changeDetector.markForCheck();

        this.subscription.add(
          this.cartService.addToCartAndOpenDrawer(variantId).subscribe({
            next: () => {
              this.inFlight = false;
              this.changeDetector.markForCheck();
            },
            error: err => {
              this.inFlight = false;
              this.logger.error('Error in addToCartAndOpenDrawer', err);
              this.changeDetector.markForCheck();
            },
          }),
        );
    }
}
