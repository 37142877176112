import {
  Component,
  OnInit,
  ElementRef,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DataService } from '../../providers/data/data.service';
import {
  Collection,
  GetCollectionsByIdsQuery,
  GetCollectionsByIdsQueryVariables,
} from '../../../common/generated-types';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { GET_COLLECTIONS_BY_IDS } from '../../../common/graphql/documents.graphql';
import { SwiperContainer } from 'swiper/element';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { isPlatformBrowser } from '@angular/common';
import { CollectionGroupData } from '../../../common/interfaces';

@Component({
  selector: 'vsf-collection-group-swiper',
  templateUrl: './collection-group-swiper.component.html',
  styleUrls: ['./collection-group-swiper.component.scss'],
})
export class CollectionSwiperComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;

  @Input() collectionGroup: CollectionGroupData;
  @Input() displayPagination: boolean = false;

  collectionsList: Collection[] = [];
  displayedCollections: Collection[] = [];
  itemsPerSlide: number = 5;
  slidesPerView: number = 1;
  selectedIndex = 0;
  totalSlides = 0;
  groupName: string;
  private destroy$ = new Subject();
  useSwiper: boolean = false;
  swiperConfig: any = {};

  private static readonly BREAKPOINT_CONFIG = [
    { query: '(max-width: 170px)', slidesPerView: 1 },
    { query: '(max-width: 320px)', slidesPerView: 1.5 },
    { query: '(max-width: 440px)', slidesPerView: 2.5 },
    { query: '(max-width: 590px)', slidesPerView: 3.5 },
    { query: '(max-width: 680px)', slidesPerView: 4.5 },
    { query: '(min-width: 681px)', slidesPerView: 5 },
  ];

  constructor(
    private dataService: DataService,
    private changeDetector: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: object,
    private logger: NGXLogger,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.groupName = this.collectionGroup?.groupName;

    if (isPlatformBrowser(this.platformId)) {
      const breakpointQueries = CollectionSwiperComponent.BREAKPOINT_CONFIG.map(config => config.query);
      this.breakpointObserver
        .observe(breakpointQueries)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          const breakpoints = result.breakpoints;

          const matchedBreakpoint = CollectionSwiperComponent.BREAKPOINT_CONFIG.find(config => breakpoints[config.query]);
          if (matchedBreakpoint) {
            this.slidesPerView = matchedBreakpoint.slidesPerView;
          } else {
            this.slidesPerView = 5;
          }
        this.fetchCollections();
      });
    }
  }

  ngAfterViewInit() {
    if (this.swiper?.nativeElement.swiper) {
      this.swiper.nativeElement.swiper.onAny((eventName: string) => this.slideEvent(eventName));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  slideEvent(eventName: string) {
    if (eventName === 'slideChange') {
      this.selectedIndex = this.swiper.nativeElement.swiper.activeIndex + 1;
    }
  }

  trackByCollectionId(index: number, item: any): string {
    return item.id;
  }

  private fetchCollections() {
    if (this.collectionGroup?.collections.length > 0) {
      const collectionsObservable = this.dataService
        .query<GetCollectionsByIdsQuery, GetCollectionsByIdsQueryVariables>(
          GET_COLLECTIONS_BY_IDS,
          {
            collectionIds: this.collectionGroup?.collections.map(
              (collection) => collection.id
            ),
          }
        )
        .pipe(
          take(1),
          takeUntil(this.destroy$),
          map((result: any) => result?.getCollectionsByIds)
        );
      collectionsObservable.subscribe((collections: Collection[]) => {
          const collectionMap = new Map(
            collections.map((collection) => [collection.id, collection])
          );
          this.displayedCollections = this.collectionGroup?.collections.map(
            (collection) => collectionMap.get(collection.id) as Collection
          ).filter(notNullOrUndefined);
          this.useSwiper = this.displayedCollections.length > this.slidesPerView;
          this.changeDetector.detectChanges();
        },
        (error) => {
          this.logger.error('Error fetching collections:', error);
        }
      );
    }
  }
}
