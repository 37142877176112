import { Route } from '@angular/router';

import { ProductListComponent } from './core/components/product-list/product-list.component';
import { OrderStatusFormComponent } from './core/components/order-status-form/order-status-form.component';
import { ReturnRequestRmaFormComponent } from './shared/components/return-request-rma-form/return-request-rma-form.component';
import { CartPageComponent } from './core/components/cart-page/cart-page.component';

export const routes: Route[] = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        pathMatch: 'full',
    },
    {
        path: 'gp',
        loadChildren: () => import('./collection/collection.module').then(m => m.CollectionModule),
    },
    {
        path: 'search',
        component: ProductListComponent,
    },
    {
        path: 'dp/:slug',
        loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
    },
    {
        path: 'product/:slug',
        loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
    },
    {
        path: 'store',
        loadChildren: () => import('./store/store.module').then(m => m.StoreModule),
    },
    {
        path: 'cart',
        component: CartPageComponent,
    },
    {
        path: 'orderstatus',
        component: OrderStatusFormComponent,
    },
    {
        path: 'return-form',
        component: ReturnRequestRmaFormComponent,
    },
    {
        path: 'policy',
        loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule),
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    },
    {
        path: 'checkout',
        loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule),
    },
    {
        path: '**',  // Wildcard route for a 404 page or redirect
        redirectTo: '',
        pathMatch: 'full'
    }
];
