<div class="newsletter-container">
    <img [src]="backgroundImage | assetPreview:'full'" alt="Background Image" class="background-image" [style.opacity]="shadowOpacity">
    <div class="newsletter-content">
      <h2>{{title}}</h2>
      <p>{{subtitle}}</p>
      <form (ngSubmit)="onSubmit()">
        <input type="email" placeholder="Enter your email" [(ngModel)]="email" name="email" required>
        <button type="submit">Submit</button>
      </form>
    </div>
</div>