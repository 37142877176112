import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { KeySellingPointData } from '../../../common/interfaces';

@Component({
    selector: 'vsf-key-selling-points-banner',
    templateUrl: './key-selling-points-banner.component.html',
    styleUrls: ['./key-selling-points-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeySellingPointsBannerComponent implements OnInit {
    @Input() keySellingPointStrings: string[] = [];
    @Input() layout: 'horizontal' | 'vertical' = 'horizontal';
    @Input() backgroundColor: string = '#ffffff';
    @Input() textColor: string = '#000000';
    @Input() isMobile: boolean = false;

    keySellingPoints: KeySellingPointData[] = [];
    gridTemplateColumns: string = '';
    constructor(private logger: NGXLogger, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        if(this.keySellingPointStrings?.length > 0) {
            this.keySellingPointStrings.forEach(
                (jsonString) => {
                    const keySellingPoint = safeJSONParse<KeySellingPointData>(jsonString, this.logger);
                    if(keySellingPoint) {
                        this.keySellingPoints.push(keySellingPoint);
                    }
                }
            );
            this.logger.debug('Key selling points:', this.layout, this.backgroundColor, this.textColor, this.keySellingPoints);
            this.gridTemplateColumns = `repeat(${this.keySellingPoints?.length}, 1fr)`;
        }
        if(this.isMobile) {
            this.layout = 'horizontal';
        }
        this.changeDetectorRef.markForCheck();
    }
}