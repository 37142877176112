import { Component, OnInit, ElementRef, Renderer2, Input, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { DataService } from '../../providers/data/data.service';
import { Collection, GetCollectionsByIdsQuery, GetCollectionsByIdsQueryVariables, GetCollectionsQuery, GetCollectionsQueryVariables } from '../../../common/generated-types';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { GET_COLLECTIONS, GET_COLLECTIONS_BY_IDS } from '../../../common/graphql/documents.graphql';
import { NGXLogger } from 'ngx-logger';
import { SwiperContainer } from 'swiper/element';
import { ColorConfig } from '../../../common/interfaces';

@Component({
  selector: 'vsf-dual-collections-swiper',
  templateUrl: './dual-collections-swiper.component.html',
  styleUrls: ['./dual-collections-swiper.component.scss']
})
export class DualCollectionsSwiperComponent implements OnInit, OnDestroy {
  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;

  @Input() collectionsList: Collection[] = [];
  @Input() collectionIdsList: string[] = [];
  @Input() colorConfig: ColorConfig | null;

  displayedCollections: Collection[] = [];
  groupedCollections: Collection[][] = [];
  swipeDirection = 'horizontal';
  selectedIndex = 0;
  itemsPerSlide = 2;
  totalSlides = 0;

  collectionLength: number;
  startIndex = 0;

  private destroy$ = new Subject();

  constructor(
    private dataService: DataService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private changeDetector: ChangeDetectorRef,
    private logger: NGXLogger
  ) {}

  async ngOnInit() {
    this.logger.info(`[DualCollectionsSwiper] init ${JSON.stringify(this.collectionsList)}`);
    if(this.collectionsList && this.collectionsList.length > 0) {
      this.displayedCollections = this.collectionsList;
    } else {
      this.displayedCollections = await this.dataService.query<GetCollectionsByIdsQuery, GetCollectionsByIdsQueryVariables>(
        GET_COLLECTIONS_BY_IDS,
        { collectionIds: this.collectionIdsList }
      ).pipe(
          take(1),
          takeUntil(this.destroy$),
          map((result: any) => result?.getCollectionsByIds)
      ).toPromise();
    }
    this.totalSlides = Math.ceil(this.displayedCollections.length / this.itemsPerSlide);
      
    // Manually group collections into slides
    const totalItemsPerSlide = this.itemsPerSlide;
    for (let i = 0; i < this.displayedCollections.length; i += totalItemsPerSlide) {
      this.groupedCollections.push(
        this.displayedCollections.slice(i, i + totalItemsPerSlide)
      );
    }
    this.changeDetector.markForCheck();
  }

  ngAfterViewInit() {
    if (this.swiper?.nativeElement.swiper) {
      this.swiper.nativeElement.swiper.onAny((eventName: string) => this.slideEvent(eventName));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  slideEvent(eventName: string) {
    if (eventName === 'slideChange') {
      this.selectedIndex = this.swiper.nativeElement.swiper.activeIndex + 1;
    }
  }

  trackByCollectionId(
    index: number,
    item: any
  ): string {
    return item.id;
  }
}