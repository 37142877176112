import { Component, Input } from '@angular/core';
import { Asset } from '../../../common/generated-types';

@Component({
  selector: 'vsf-store-logo',
  templateUrl: './store-logo.component.html',
  styleUrls: ['./store-logo.component.scss']
})
export class StoreLogoComponent {
  @Input() normalLogo: Asset;
  @Input() nameLogo: Asset;
  @Input() smallLogo: Asset;
  @Input() name: string;
  @Input() path: string;
  @Input() logoHeight: string = '50px';
  @Input() displayName: boolean;
  @Input() isMobile: boolean;
}
