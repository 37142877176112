<div class="product-carousel">
  <div *ngIf="displayCarouselTitle" class="product-carousel-title">
    <p>{{ collectionName }}</p>
    <div class="title-underline"></div>
  </div>
  <div class="product-list-container">
    <vsf-product-card-v2 *ngFor="let product of displayedProducts; trackBy: trackByProductId"
                      [product]="product"
                      [displaySubtitle]="displaySubtitle"
                      [displayPrice]="displayPrice"
                      [displayBuyButton]="displayBuyButton"
                      [displayAlternativeImage]="displayAlternativeImage"
                      [imgType]="'rectangle'"
                      [imgSize]="imgSize"
                      class="card product-card">
    </vsf-product-card-v2>
  </div>
  <button class="arrow arrow-left" (click)="showPreviousItems()" *ngIf="startIndex > 0">
    <span><fa-icon icon="chevron-left"></fa-icon></span>
  </button>
  <button class="arrow arrow-right" (click)="showNextItems()" *ngIf="startIndex + itemsPerSlide < (products$ | async)?.length">
    <span><fa-icon icon="chevron-right"></fa-icon></span>
  </button>
</div>