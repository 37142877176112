import { gql } from 'apollo-angular';
import e from 'express';


export const ASSET_FRAGMENT = gql`
    fragment Asset on Asset {
        id
        width
        height
        name
        type
        preview
        source
        focalPoint {
            x
            y
        }
    }
`;

export const PRODUCT_REVIEW_FRAGMENT = gql`
    fragment ProductReview on ProductReview {
        id
        createdAt
        updatedAt
        summary
        body
        rating
        authorName
        upvotes
        downvotes
        state
    }
`

export const PRODUCT_CUSTOM_FIELDS_FRAGMENT = gql`
    fragment ProductCustomFields on ProductCustomFields {
        HeroTitle
        HeroSubtitle
        HeroImages {
            ...Asset
        }
        HeroVideo {
            ...Asset
        }
        OGDescription
        OGImage {
            ...Asset
        }
        ProductKeySellingPoints
        ProductSubtitle
        ProductFeatures
        ProductDisclaimer
        ProductMarketingBanners
        reviewRating
        reviewCount
        featuredReview {
            ...ProductReview
        }
    }
    ${ASSET_FRAGMENT}
    ${PRODUCT_REVIEW_FRAGMENT}
`;

export const VARIANT_CUSTOM_FIELDS_FRAGMENT = gql` 
    fragment ProductVariantCustomFields on ProductVariantCustomFields {
        MSRP
    }
`;

export const CART_FRAGMENT = gql`
    fragment Cart on Order {
        id
        code
        state
        active
        createdAt
        updatedAt
        couponCodes
        orderPlacedAt
        customer {
            id
            firstName
            customFields {
                nickname
            }
        }
        lines {
            id
            featuredAsset {
                ...Asset
            }
            unitPrice
            unitPriceWithTax
            quantity
            linePriceWithTax
            discountedLinePriceWithTax
            productVariant {
                id
                name
                sku
                price
                featuredAsset {
                    ...Asset
                }
                options {
                    name
                }
                product {
                    id
                    name
                    slug
                    channels {
                        id
                        code
                        seller {
                            id
                            name
                            customFields {
                                returnPolicy
                            }
                        }
                    }
                }
                customFields {
                    MSRP
                }
            }
            discounts {
                amount
                amountWithTax
                description
                adjustmentSource
                type
            }
        }
        totalQuantity
        subTotal
        subTotalWithTax
        total
        totalWithTax
        shipping
        shippingWithTax
        shippingLines {
            priceWithTax
            shippingMethod {
                id
                code
                name
                description
            }
        }
        discounts {
            amount
            amountWithTax
            description
            adjustmentSource
            type
        }
    }
    ${ASSET_FRAGMENT}
`;

export const COUNTRY_FRAGMENT = gql`
    fragment Country on Country {
        id
        code
        name
        enabled
    }
`;

export const ORDER_ADDRESS_FRAGMENT = gql`
    fragment OrderAddress on OrderAddress {
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country
        phoneNumber
    }
`;

export const COLLECTION_FRAGMENT = gql`
    fragment Collection on Collection {
        id
        name
        slug
        description
        parent {
            id
            name
            slug
        }
        featuredAsset {
            ...Asset
        }
        storeSite {
            id
            name
            identifier
            sellerChannelId
            colorConfig
        }
        customFields {
            heroSection
        }
    }
    ${ASSET_FRAGMENT}
`;

export const COLLECTION_HERO_FRAGMENT = gql`
    fragment CollectionHero on Collection {
        id
        name
        slug
        customFields {
            heroSection
        }
    }
`;

export const FACET_VALUE_FRAGMENT = gql`
    fragment FacetValue on FacetValue {
        id
        name
        code
        customFields {
            priority
        }
        facet {
            id
            name
            code
        }
    }
    ${ASSET_FRAGMENT}
`;

export const FACET_FRAGMENT = gql`
    fragment Facet on Facet {
        id
        name
        code
        values {
            ...FacetValue
        }
    }
    ${FACET_VALUE_FRAGMENT}
`;

export const STORESITE_INFO_FRAGMENT = gql`
    fragment StoreSiteInfo on StoreSite {
        id
        name
        identifier
        seller {
            customFields {
                email
            }
        }
        sellerChannelId
        headerMessage
        colorConfig
        logoSection
        snsInfoSection
        heroSection
        navigationMenu
        policyContentList
    }
`;

export const STORESITE_MAIN_FRAGMENT = gql`
    fragment StoreSiteMain on StoreSite {
        id
        name
        identifier
        sellerChannelId
        colorConfig
        layoutOrder
        heroSection
        featuredProductReviewList
        emailSubscriptionSection
        uniqueSellingPointsList
        featuredProductCollectionList
        collectionListForShopByGroupList
        collectionForSeriesList
    }
`;

export const FULFILLMENT_FRAGMENT = gql`
    fragment Fulfillment on Fulfillment {
        id
        state
        createdAt
        updatedAt
        method
        lines {
            orderLineId
            quantity
        }
        trackingCode
    }
`;

export const ADDRESS_FRAGMENT = gql`
    fragment Address on Address {
        id
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country {
            id
            code
            name
        }
        phoneNumber
        defaultShippingAddress
        defaultBillingAddress
    }
`;

export const ERROR_RESULT_FRAGMENT = gql`
    fragment ErrorResult on ErrorResult {
        errorCode
        message
    }
`;

export const PAYMENT_INTENT_FRAGMENT = gql`
    fragment PaymentIntentResponse on PaymentIntentResponse {
        paymentIntentId
        clientSecret
        showRecaptcha
    }
`;