import { Component, OnInit, ElementRef, Renderer2, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DataService } from '../../providers/data/data.service';
import { Collection, GetCollectionsByIdsQuery, GetCollectionsByIdsQueryVariables } from '../../../common/generated-types';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { GET_COLLECTIONS_BY_IDS } from '../../../common/graphql/documents.graphql';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { CollectionGroupData } from '../../../common/interfaces';

@Component({
  selector: 'vsf-collection-group-carousel',
  templateUrl: './collection-group-carousel.component.html',
  styleUrls: ['./collection-group-carousel.component.scss']
})
export class CollectionGroupCarouselComponent implements OnInit, OnDestroy {
  @Input() collectionGroup: CollectionGroupData;
  collectionsList: Collection[] = [];
  displayedCollections: Collection[] = [];
  startIndex = 0;
  itemsPerPage = 5;
  groupName: string;
  private destroy$ = new Subject();

  constructor(
    private dataService: DataService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private logger: NGXLogger
  ) {}

  ngOnInit() {
    this.logger.debug(`[CollectionGroup]${JSON.stringify(this.collectionGroup)}`);
    this.groupName = this.collectionGroup?.groupName;
    if(this.collectionGroup?.collections.length > 0) {
      const collectionsOberservable = this.dataService.query<GetCollectionsByIdsQuery, GetCollectionsByIdsQueryVariables>(
        GET_COLLECTIONS_BY_IDS,
        { collectionIds: this.collectionGroup?.collections.map(collection => collection.id) }
      ).pipe(
          take(1),
          takeUntil(this.destroy$),
          map((result: any) => result?.getCollectionsByIds)
      );
      collectionsOberservable.subscribe(
        (collections: Collection[]) => {
          const collectionMap = new Map(
            collections.map(collection => [collection.id, collection])
          );
          this.collectionsList = this.collectionGroup?.collections
            .map(collection => collectionMap.get(collection.id))
            .filter(notNullOrUndefined);
          this.logger.debug(`[CollectionGroup]collectionsList:${this.collectionsList.length} ${JSON.stringify(this.collectionsList)}`);
          this.updateDisplayedCollections();
          this.changeDetectorRef.markForCheck();
        },
        (error) => {
          this.logger.error('Error fetching collections:', error);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackByCollectionId(index: number, item:any): string {
    return item.id;
  }

  showPreviousItems(): void {
    this.startIndex -= this.itemsPerPage;
    this.updateDisplayedCollections();
  }

  showNextItems(): void {
    this.startIndex += this.itemsPerPage;
    this.updateDisplayedCollections();
  }

  setItemsPerPage(itemsPerPage: number = this.itemsPerPage): void {
    this.itemsPerPage = itemsPerPage;
    this.renderer.setProperty(this.elementRef.nativeElement, 'style', `--items-per-page: ${this.itemsPerPage}`);
    this.updateDisplayedCollections();
  }

  private updateDisplayedCollections(): void {
      this.displayedCollections = this.collectionsList.slice(this.startIndex, this.startIndex + this.itemsPerPage);
  }
}