<ng-template [ngIf]="product">
    <a class="block" prefetch='intent' [routerLink]="[productBaseUrl, product.slug]">
        <div class="relative w-full h-full full-size"
                (mouseenter)="onMouseEnter()"
                (mouseleave)="onMouseLeave()">
            <div class="aspect-ratio-container" [ngClass]="imgType">
                <img loading="lazy"
                    [src]="featuredAsset | assetPreview:'small'"
                    [alt]="product.name"
                    style="pointer-events: none;"
                    [ngClass]="{'product-image rectangle w-full h-full object-cover rounded-xl aspect-[1/1]': imgType === 'rectangle', 
                                'product-square-image square w-full h-full object-cover rounded-xl aspect-[1/1]': imgType === 'square'}"
                />
            </div>
            <div *ngIf="!inStock"
                    class="absolute top-1 right-1 px-2 py-1 bg-white text-black border border-black"
                    style="font-size: 14px;">
                SOLD OUT
            </div>
            <div *ngIf="displayBuyButton && inStock && hover" class="absolute opacity-40 bottom-0 mb-4 w-full flex justify-center ">
                <button (click)="onButtonClick($event)"
                    class="bg-white text-black px-4 py-2 whitespace-nowrap rounded-md border border-black hover:bg-[#B6E7B0] focus:outline-none"
                    [disabled]="inFlight"
                >
                    ADD TO CART
                </button>
            </div>
        </div>
        <div class="product-name font-bold mt-2 mb-1 text-[#2E3742]" [title]="product.productName">
            {{ product.productName }}
        </div>
        <div *ngIf="displaySubtitle" class="product-subtitle mb-1 text-[#95A1AF]" [title]="product.productSubtitle" >
            {{ product.productSubtitle || '&nbsp;' }}
        </div>
        <div *ngIf="displayPrice" class="flex flex-col product-price font-medium font-semibold text-[#2E3742]">
            <ng-container *ngIf="msrp && discountPercentage; else onlyPrice">
                <div class="text-base">
                    <span class="text-grey-500 line-through">
                        {{ msrp | formatPrice }}
                    </span>
                    <span class="text-red-500 ml-2">
                        {{ product.price.min | formatPrice }} ({{ discountPercentage | number:'1.0-0' }}% off)
                    </span>
                </div>
            </ng-container>
            <ng-template #onlyPrice>
                <div>
                    <ng-template [ngIf]="product.price.min === product.price.max" [ngIfElse]="range">
                        {{ product.price.min | formatPrice }}
                    </ng-template>
                        <ng-template #range>
                            From {{ product.price.min | formatPrice }}
                        </ng-template>
                    </div>
            </ng-template>
        </div>
    </a>
</ng-template>
