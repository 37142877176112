<div id="mobile-indicator" class="is-mobile"></div>
<div class="hero-slider flex flex-col relative"
    [style.min-height]="{'min-height':isMobile? '100px':'350px', 'max-height':isMobile? '220px':'800px'}">
  <div class="item">
    <div *ngIf="medias?.length > 0">
      <swiper-container appSwiper #swiper pagination.type="progressbar" pagination-clickable="true"
          centered-slides="true" autoplay-delay="5000" autoplay-disable-on-interaction="false"
          rewind="true" class="flex-grow object-cover custom-pagination" [style.z-index]="1">
        <swiper-slide *ngFor="let media of medias">
          <div  class="flex justify-center items-center w-full">
              <div *ngIf="media.type === 'IMAGE'">
                <img [src]="media | assetPreview: 'full'"
                    style="pointer-events: none;"
                    class="object-cover w-full h-full"
                    [ngClass]="isMobile? 'aspect-[16/9]' : ''"
                    alt="Hero Image"/>
                <div class="absolute inset-0 bg-gray-500" [style.opacity]="shadowOpacity"></div>
              </div>
              <div *ngIf="media.type === 'VIDEO'" class="w-auto">
                <div class="w-auto max-h-none md:max-h-[800px] mx-auto">
                  <video style="z-index:1; pointer-events: auto;" [muted]=true class="object-contain w-full h-full" autoplay playsinline controls>
                    <source [src]="media.source" type="video/mp4" alt="Hero Video">
                  </video>
                  <div class="absolute inset-0 bg-gray-500" [style.opacity]="shadowOpacity" style="pointer-events: none;"></div>
                </div>
              </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
    <div
      *ngIf="layoutType === 'left-text-full-media'"
      style="z-index: 5; pointer-events: none;"
      class="absolute inset-0 flex flex-col items-start justify-between px-2 md:px-8 top-1/2 transform -translate-y-1/2 left-0"
    >
      <div class="hero-text-left">
        <div [ngStyle]="{'color': textColor, 'font-size': isMobile?titleSizeMobile:titleSize}" class="hero-title whitespace-nowrap" [innerHTML]="title | safeHtml"></div>
        <div [ngStyle]="{'color': textColor}" class="hero-subtitle mt-2" [innerHTML]="subtitle | safeHtml"></div>
        <div *ngIf="button?.enabled" class="button-container">
          <button (click)="buttonClick($event); $event.stopPropagation()"
              class="shop-button mt-12 md:mt-20 justify-center rounded-lg hover:bg-grey-700 focus:outline-none"
              style="pointer-events: auto;"
              [ngStyle]="{'background-color': button.backgroundColor, 'color': button.textColor}"
              [ngClass]="{ 'opacity-80': inFlight }"
              [disabled]="inFlight">
              <div class="flex-container justify-center">
                <p>{{button.text}}</p>
                <fa-icon [icon]="['fas', 'caret-right']" class="ml-2 color-black icon-size"></fa-icon>
              </div>
          </button>
        </div>
      </div>
    </div>

    <div
      *ngIf="layoutType === 'right-text-full-media'"
      style="z-index: 5; pointer-events: none;"
      class="absolute inset-0 flex flex-col items-end justify-between px-2 md:px-8 top-1/2 transform -translate-y-1/2 right-0"
    >
      <div class="hero-text-right">
        <div [ngStyle]="{'color': textColor, 'font-size': isMobile?titleSizeMobile:titleSize}" class="hero-title whitespace-nowrap" [innerHTML]="title | safeHtml"></div>
        <div [ngStyle]="{'color': textColor}" class="hero-subtitle mt-2" [innerHTML]="subtitle | safeHtml"></div>
        <div *ngIf="button?.enabled" class="button-container">
          <button (click)="buttonClick($event); $event.stopPropagation()"
              class="shop-button mt-4 md:mt-20 justify-center rounded-lg hover:bg-grey-700 focus:outline-none"
              style="pointer-events: auto;"
              [ngStyle]="{'background-color': button.backgroundColor, 'color': button.textColor}"
              [ngClass]="{ 'opacity-80': inFlight }"
              [disabled]="inFlight">
              <div class="flex-container justify-center">
                <p>{{button.text}}</p>
                <fa-icon [icon]="['fas', 'caret-right']" class="ml-2 color-black icon-size"></fa-icon>
              </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>