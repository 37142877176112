<div class="ml-2 mr-2">
    <div class="featured-review-title">
        <p>Customers say</p>
        <div class="featured-review-title-underline"></div>
    </div>
    <div *ngIf="reviewsList.length > 0" class="max-w-2xl mx-auto py-4 lg:max-w-6xl lg:px-8">
        <div class="border-t border-gray-200 divide-y divide-gray-200"
            [ngClass]="isMobile ? 'space-y-4' : 'space-y-10'">
            <div
                *ngFor="let review of reviewsList"
                class="lg:grid lg:grid-cols-12 lg:gap-x-8"
                [ngClass]="isMobile ? 'pb-4 pt-4' : 'pt-6 pb-6'"
            >
                <div *ngIf="isMobile; else desktopReview">
                    <div class="flex flex-col">
                        <p class="font-medium text-gray-900">
                            {{review.authorName ? review.authorName : 'Anonymous'}}
                        </p>
                        <div class="flex flex-row justify-between mb-2">
                            <time class="text-gray-500">
                                {{ review.updatedAt | date:'MMMM d, y' }}
                            </time>
                            <div class="flex flex-row justify-end">
                                <vsf-product-review-indicator
                                    [inputScore]="review.rating"
                                    [displayReviewNumbers]="false">
                                </vsf-product-review-indicator>
                                <strong class="text-sm text-gray-700">
                                    {{review.rating}}
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <p class="text-sm font-medium text-gray-900 mb-2">
                            {{review.summary }}
                        </p>
                        <p
                            class="text-sm text-gray-500 review-content"
                            [innerHTML]="review.body"
                            [title]="review.body"
                        ></p>
                    </div>
                </div>
                <ng-template #desktopReview>
                    <div
                        class="lg:col-start-5 lg:col-span-8 xl:col-start-4 xl:col-span-9 xl:grid xl:grid-cols-3 xl:gap-x-8 xl:items-start">
                        <div class="flex items-center xl:col-span-1">
                            <div class="flex items-center">
                                <vsf-product-review-indicator
                                    class="mb-2"
                                    [inputScore]="review.rating"
                                    [displayReviewNumbers]="false">
                                </vsf-product-review-indicator>
                            </div>
                            <p class="ml-3 text-sm text-gray-700">
                                <strong>{{review.rating}}</strong>
                                <span class="sr-only">out of 5 stars</span>
                            </p>
                        </div>

                        <div class="mt-4 lg:mt-6 xl:mt-0 xl:col-span-2">
                            <p class="text-sm font-medium text-gray-900">
                                {{review.summary }}
                            </p>

                            <div><p
                                class="mt-3 space-y-6 text-sm text-gray-500 review-content"
                                [innerHTML]="review.body"
                                [title]="review.body"
                            ></p></div>
                        </div>
                    </div>

                    <div
                        class="mt-6 flex items-center text-sm lg:mt-0 lg:col-start-1 lg:col-span-4 lg:row-start-1 lg:flex-col lg:items-start xl:col-span-3">
                        <p class="font-medium text-gray-900">
                            {{review.authorName ? review.authorName : 'Anonymous'}}
                        </p>
                        <time
                            dateTime={review.updatedAt}
                            class="ml-4 border-l border-gray-200 pl-4 text-gray-500 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0"
                        >
                            {{ review.updatedAt | date:'MMMM d, y' }}
                        </time>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
    <hr>
</div>
