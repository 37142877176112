import {gql} from 'apollo-angular';
import { ASSET_FRAGMENT } from '../../../common/graphql/fragments.graphql';


export const GET_ACTIVE_CHANNEL = gql`
    query GetActiveChannel {
        activeChannel {
            id
            code
            currencyCode
            defaultLanguageCode
            customFields {
                heroSection,
                logoSection,
                emailSubscriptionSection,
                HeaderMessage,
                StoreName,
                StoreLogo {
                    ...Asset
                },
                StoreSmallLogo {
                    ...Asset
                },
                StoreNameLogo {
                    ...Asset
                },
                StoreBrowserIcon {
                    ...Asset
                },
                FeaturedProductSlug,
                StoreHeroTitle,
                StoreHeroSubtitle,
                StoreHeroImages {
                    ...Asset
                },
                StoreHeroVideo {
                    ...Asset
                },
                StoreDisclaimer,
                StoreCopyrightNotice,
                DefaultCollectionLabel,
                DefaultCollectionSlug,
                HotSalesCollectionLabel,
                HotSalesCollectionSlug,
                NewArrivalsCollectionLabel,
                NewArrivalsCollectionSlug
            }
        }
    }
    ${ASSET_FRAGMENT}
`;