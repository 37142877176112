import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Asset, GetStoreAboutInfoQuery, GetStoreAboutInfoQueryVariables } from '../../../common/generated-types';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../providers/data/data.service';
import { filter, map, take } from 'rxjs/operators';
import { GET_STORESITE_ABOUT_INFO } from '../../../common/graphql/documents.graphql';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { isPlatformBrowser } from '@angular/common';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { AboutPageData, ColorConfig } from '../../../common/interfaces';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'vsf-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AboutPageComponent implements OnInit, AfterViewChecked {
    identifier: string;
    aboutPage: AboutPageData | null;
    colorConfig: ColorConfig | null;
    posterAsset: Asset;
    title: string;
    text: string;
    citation: string;
    featuredAsset: Asset;
    isMobile: boolean | null = null;
    readyToDisplay$ = new BehaviorSubject<boolean>(false);
  
    constructor(private logger: NGXLogger,
                private route: ActivatedRoute,
                private dataService: DataService,
                private changeDetectorRef: ChangeDetectorRef,
                @Inject(PLATFORM_ID) private platformId: object) { }
  
    ngOnInit(): void {
        this.checkViewport();
        this.logger.info('[About page] init');
        this.route.paramMap.pipe(
            map(paramMap => paramMap.get('identifier')),
            filter(notNullOrUndefined),
            take(1),
        ).subscribe(identifier => {
            this.logger.info('[About page] identifier:', identifier);
            this.dataService.query<GetStoreAboutInfoQuery, GetStoreAboutInfoQueryVariables>(GET_STORESITE_ABOUT_INFO, {
            identifier,
            }).subscribe(result => {
                this.colorConfig = safeJSONParse<ColorConfig>(result?.storeSite?.colorConfig, this.logger);
                this.aboutPage = safeJSONParse<AboutPageData>(result?.storeSite?.aboutPageContent, this.logger);
                if(this.aboutPage) {
                    this.logger.info('[About page] content:', this.aboutPage);
                    this.posterAsset = this.aboutPage.posterAsset || this.posterAsset;
                    this.title = this.aboutPage.title || this.title;
                    this.text = this.aboutPage.text || this.text;
                    this.citation = this.aboutPage.citation || this.citation;
                    this.featuredAsset = this.aboutPage.featuredAsset || this.featuredAsset;
                    this.readyToDisplay$.next(true);
                    this.changeDetectorRef.markForCheck();
                }
            });
        });
    }

    ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
    }

    @HostListener('window:resize', [])
    onResize() {
      this.checkViewport();
    }

    private checkViewport() {
      if(isPlatformBrowser(this.platformId)){
        const mobileIndicator = document.getElementById('mobile-indicator');
        if(mobileIndicator && window) {
            this.isMobile = window.getComputedStyle(mobileIndicator).display !== 'none';
        }
      }
    }
}
