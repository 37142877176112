import {gql} from 'apollo-angular';


import { ADDRESS_FRAGMENT, ASSET_FRAGMENT, COUNTRY_FRAGMENT, CART_FRAGMENT, ERROR_RESULT_FRAGMENT, STORESITE_MAIN_FRAGMENT, COLLECTION_FRAGMENT, STORESITE_INFO_FRAGMENT, COLLECTION_HERO_FRAGMENT, PRODUCT_REVIEW_FRAGMENT } from './fragments.graphql';

export const GET_CUSTOMER_ADDRESSES = gql`
    query GetCustomerAddresses {
        activeCustomer {
            id
            addresses {
                ...Address
            }
        }
    }
    ${ADDRESS_FRAGMENT}
`;
export const DELETE_CUSTOMER_ADDRESS = gql`
mutation DeleteCustomerAddress($id: ID!) {
    deleteCustomerAddress(id: $id) {
        success
    }
}
`;
export const GET_AVAILABLE_COUNTRIES = gql`
    query GetAvailableCountries {
        availableCountries {
            ...Country
        }
    }
    ${COUNTRY_FRAGMENT}
`;

export const GET_ACTIVE_CUSTOMER = gql`
    query GetActiveCustomer {
        activeCustomer {
            id
            firstName
            lastName
            emailAddress
            phoneNumber
            customFields {
                nickname
            }
        }
    }
`;

export const SUBMIT_PRODUCT_REVIEW = gql`
    mutation SubmitProductReview($input: SubmitProductReviewInput!) {
        submitProductReview(input: $input) {
            __typename
            ...on ProductReview {
                id
                summary
                body
                rating
                authorName
                state
            }
            ...on SubmitProductReviewError {
                submitProductReviewErrorMessage
            }
        }
    }
`;

export const GET_REVIEWS_FOR_PRODUCT = gql`
    query GetReviewForProduct($productId: ID!, $options: ProductReviewListOptions) {
        product(id: $productId) {
            id
            reviews(options: $options) {
                items {
                    id
                    summary
                    body
                    rating
                    authorName
                    state
                }
                totalItems
            }
        }
    }
`;

export const GET_PRODUCT_REVIEW = gql`
    query GetProductReview($productId: ID!, $customerId: ID) {
        productReview(productId: $productId, customerId: $customerId) {
            id
            summary
            body
            rating
            authorName
            state
        }
    }
`;

export const GET_REVIEWS_BY_IDS = gql`
    query GetReviewsByIds($reviewIds: [ID!]!) {
        getReviewsByIds(ids: $reviewIds) {
            ...ProductReview
        }
    }
    ${PRODUCT_REVIEW_FRAGMENT}
`;

export const GET_COLLECTIONS_BY_IDS = gql`
    query GetCollectionsByIds($collectionIds: [ID!]!) {
        getCollectionsByIds(ids: $collectionIds) {
            ...Collection
        }
    }
    ${COLLECTION_FRAGMENT}
`;

export const GET_STORESITE_INFO = gql`
    query GetStoreInfo($identifier: String!) {
        storeSite(identifier: $identifier) {
            ...StoreSiteMain
        }
    }
    ${STORESITE_MAIN_FRAGMENT}
`;

export const GET_FACET = gql`
    query GetFacet($id: ID!) {
        facet(id: $id) {
            id
            name
            values {
                id
                name
                code
            }
        }
    }
`;


export const SEARCH_PRODUCTS = gql`
    query SearchProducts($input: SearchInput!) {
        search(input: $input) {
            items {
                productId
                slug
                productName
                description
                productVariantId
                productSubtitle
                productVariantMSRP
                inStock
                price {
                    ... on PriceRange {
                        min
                        max
                    }
                }
                productAsset {
                    id
                    preview
                    focalPoint {
                        x
                        y
                    }
                }
                productAlternativeAsset {
                    id
                    preview
                    focalPoint {
                        x
                        y
                    }
                }
            }
            totalItems
            facetValues {
                count
                facetValue {
                    id
                    name
                    facet {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const GET_COLLECTION = gql`
    query GetCollection($id: ID, $slug: String) {
        collection(id: $id, slug: $slug) {
            id
            name
            slug
            description
            featuredAsset {
                ...Asset
            }
            breadcrumbs {
                id
                slug
                name
            }
            children {
                id
                slug
                featuredAsset {
                    ...Asset
                }
                name
            }
            storeSite {
                id
                name
                identifier
                sellerChannelId
                colorConfig
                collectionListForShopByGroupList
                collectionForSeriesList
            }
            customFields {
                heroSection
            }
        }
    }
    ${ASSET_FRAGMENT}
`;


export const GET_COLLECTIONS = gql`
    query GetCollections($options: CollectionListOptions) {
        collections(options: $options) {
            items {
                id
                name
                slug
                parent {
                    id
                    name
                    slug
                }
                featuredAsset {
                    ...Asset
                }
            }
        }
    }
    ${ASSET_FRAGMENT}
`;

export const GET_PRODUCT_HEADER_INFO = gql`
    query GetProductHeaderInfo($slug: String!) {
        product(slug: $slug) {
            name
            description
            featuredAsset {
                ...Asset
            }
            storeSite {
                ...StoreSiteInfo
            }
            customFields {
                ProductSubtitle
                OGDescription
                heroSection
                OGImage {
                    ...Asset
                }
                ProductDisclaimer
            }
        }
    }
    ${ASSET_FRAGMENT}
    ${STORESITE_INFO_FRAGMENT}
`;

export const GET_COLLECTION_HEADER_INFO = gql`
    query GetCollectionHeaderInfo($slug: String!) {
        collection(slug: $slug) {
            name
            storeSite {
                ...StoreSiteInfo
            }
        }
    }
    ${STORESITE_INFO_FRAGMENT}
`;

export const GET_STORESITE_HEADER_INFO = gql`
    query GetStoreSiteHeaderInfo($identifier: String!) {
        storeSite(identifier: $identifier) {
            ...StoreSiteInfo
        }
    }
    ${STORESITE_INFO_FRAGMENT}
`;

export const GET_STORESITE_ABOUT_INFO = gql`
    query GetStoreAboutInfo($identifier: String!) {
        storeSite(identifier: $identifier) {
            name
            identifier
            colorConfig
            aboutPageContent
        }
    }
`;

export const GET_STORESITE_FAQ_INFO = gql`
    query GetStoreFaqInfo($identifier: String!) {
        storeSite(identifier: $identifier) {
            name
            identifier
            colorConfig
            faqPageContent
        }
    }
`;

export const GET_STORESITE_CONTACT_INFO = gql`
    query GetStoreContactInfo($identifier: String!) {
        storeSite(identifier: $identifier) {
            name
            seller {
                customFields {
                    email
                }
            }
            identifier
            colorConfig
            contactPageContent
        }
    }
`;

export const GET_STORESITE_POLICY_LIST = gql`
    query GetStorePolicyList($identifier: String!) {
        storeSite(identifier: $identifier) {
            name
            identifier
            colorConfig
            policyContentList
        }
    }
`;

export const APPLY_COUPON = gql`
    mutation ApplyCoupon($couponCode: String!) {
        applyCouponCode(couponCode: $couponCode) {
            ... on Order {
                id
                code
                subTotal
                discounts {
                    description
                    amount
                    amountWithTax
                }
            }
            ... on CouponCodeExpiredError {
                message
            }
            ... on CouponCodeInvalidError {
                message
            }
            ... on CouponCodeLimitError {
                message
            }
        }
    }
`;

export const REMOVE_COUPON = gql`
    mutation RemoveCoupon($couponCode: String!) {
        removeCouponCode(couponCode: $couponCode) {
            ... on Order {
                id
                code
                subTotal
                discounts {
                    description
                    amount
                    amountWithTax
                }
            }
        }
    }
`;

export const GET_PRODUCT_ALL_DETAIL = gql`
    query GetProductAllDetail($slug: String!) {
        product(slug: $slug) {
            id
            name
            slug
            description
            variants {
                id
                name
                options {
                    id
                    code
                    name
                    groupId
                }
                currencyCode
                price
                priceWithTax
                sku
                stockLevel
                featuredAsset {
                    ...Asset
                }
                assets {
                    ...Asset
                }
                customFields {
                    MSRP
                }
            }
            optionGroups {
                id
                code
                name
                options {
                    id
                    code
                    name
                    customFields {
                        Priority
                    }
                }
            }
            storeSite {
                id
                name
                identifier
                sellerChannelId
                colorConfig
                collectionListForShopByGroupList
                featuredProductCollectionList
            }

            customFields {
                heroSection
                HeroTitle
                HeroSubtitle
                HeroImages {
                    ...Asset
                }
                HeroVideo {
                    ...Asset
                }
                OGDescription
                OGImage {
                    ...Asset
                }
                ProductKeySellingPoints
                ProductSubtitle
                ProductFeatures
                ProductDisclaimer
                ProductMarketingBanners
                reviewRating
                reviewCount
                featuredReview {
                    id
                    summary
                    body
                    rating
                    authorName
                    state
                }
                salesNumber
                salesStatement
                alternativeFeaturedAssets {
                    ...Asset
                }
                crossSellProductCollection {
                    id
                    name
                    slug
                }
            }
            featuredAsset {
                ...Asset
            }
            assets {
                ...Asset
            }
            collections {
                id
                slug
                breadcrumbs {
                    id
                    name
                    slug
                }
            }
        }
    }
    ${ASSET_FRAGMENT}
`;

export const ADD_TO_CART = gql`
    mutation AddToCart($variantId: ID!, $qty: Int!) {
        addItemToOrder(productVariantId: $variantId, quantity: $qty) {
            ...Cart
            ...ErrorResult
            ...on InsufficientStockError {
                order {
                    ...Cart
                }
            }
        }
    }
    ${CART_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;
